/*
blockModule.width // the width of the block
blockModule.height // the height of the block
blockModule.offsetTop // How far down the page is the block
blockModule.scrollTop // How far the page is scrolled
blockModule.delta // How many pixels away the block is from being perfectly in frame
blockModule.deltaP // Percentage away the block is from being perfectly in frame
blockModule.easedDeltaP // Same thing but eased for smoothness
blockModule.$canvas // the canvas DOM object
blockModule.ctx // the canvas context('2d')
blockModule.$el // the block container
blockModule.velocity // how fast the user is scrolling, this is eased
blockModule.status // 0 = INACTIVE, 1 = VISIBLE, 2 = ACTIVE
blockModule.throttle(fn, timeout) // if you want to throttle a function, example 15 fps would be blockModule.throttle(fn, 1000 / 15)
blockModule.retina(val) // returns val or val * 2 if retina display
blockModule.init(fn) // function to run once the block is initialized
*/

export function BlockModule(selectorOrDOMObject, options = {}) {
  this.width = 0;
  this.height = 0;
  this.offsetTop = 0;
  this.scrollTop = 0;
  this.delta = 0;
  this.deltaP = 0;
  this.easedDeltaP = 0;
  this.inFramePercentage = 0;
  this.$el =
    typeof selectorOrDOMObject === 'string'
      ? document.querySelector(selectorOrDOMObject)
      : selectorOrDOMObject;
  this.hasCanvas = options.hasCanvas;
  if (this.hasCanvas) {
    this.$canvas = document.createElement('canvas');
    this.ctx = this.$canvas.getContext('2d');
    this.$el.appendChild(this.$canvas);
  }
  this.velocity = 0;
  this.prevScrollTop = 0;
  this.ACTIVE = 2;
  this.VISIBLE = 1;
  this.INACTIVE = 0;
  let velocityClear;
  this.status = this.INACTIVE;

  let snapTimeout;
  const SNAP_DELAY = 800;

  let isMobile = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      isMobile = true;
  })(navigator.userAgent || navigator.vendor || window.opera);

  const throttleTracker = {};
  this.throttle = function (fn, fps) {
    throttleTracker[fn] = throttleTracker[fn] || 0;
    const now = new Date().getTime();
    if (now - throttleTracker[fn] >= fps) {
      throttleTracker[fn] = now;
      fn();
    }
  };
  this.frame = function () {
    requestAnimationFrame(this.frame.bind(this));
    if (this.status === 2 && options.activeFrame) {
      options.activeFrame(this);
    } else if (this.status === 1 && options.visibleFrame) {
      options.visibleFrame(this);
    } else if (this.status === 0 && options.invisibleFrame) {
      options.invisibleFrame(this);
    }
    this.easedDeltaP += (this.deltaP - this.easedDeltaP) / 50;
  };
  this.clear = function () {
    if (this.hasCanvas) {
      this.ctx.clearRect(0, 0, this.$canvas.width, this.$canvas.height);
    }
  };
  this.updateScrollPosition = function () {
    this.offsetTop = this.$el.offsetTop;
    const prevState = this.status;
    this.prevScrollTop = this.scrollTop;
    this.scrollTop = window.scrollY;
    const v = this.scrollTop - this.prevScrollTop;
    this.velocity += (v - this.velocity) / 5;
    clearTimeout(velocityClear);
    velocityClear = setTimeout(() => {
      this.velocity = 0;
    }, 200);
    this.delta = this.offsetTop - this.scrollTop;
    this.deltaP = this.delta / this.height;
    const adp = Math.abs(this.deltaP);
    if (adp < 0.15) {
      this.status = this.ACTIVE;
    } else if (adp < 0.99) {
      this.status = this.VISIBLE;
    } else {
      this.status = this.INACTIVE;
    }

    this.inFramePercentage = 1 - adp;

    if (this.status !== this.INACTIVE && this.deltaP < 0) {
      if (options.themeColor) {
        let color;
        if (typeof options.themeColor === 'function') {
          color = options.themeColor(this);
        } else {
          color = options.themeColor;
        }
        try {
          document.head.querySelector('[name=theme-color]').content = color;
          document.head.querySelector(
            '[name=msapplication-TileColor]',
          ).content = color;
          // document.getElementById("float-theme").style.background = color;
        } catch (_) {}
      }
    }

    if (this.status !== prevState) {
      if (options.stateChange) {
        options.stateChange(this.status, prevState, this);
      }
    }

    /*
     * Snap logic
     */
    clearTimeout(snapTimeout);
    if (!options.disableSnap) {
      snapTimeout = setTimeout(() => {
        if (adp < 0.1) {
          window.scrollTo({ top: this.$el.offsetTop, behavior: 'smooth' });
        }
      }, SNAP_DELAY);
    }
  };
  this.retina = function (size) {
    return size * window.devicePixelRatio;
  };
  this.resize = function () {
    // isMobile
    const newWidth = this.$el.offsetWidth;
    const newHeight = this.$el.offsetHeight;
    let doResize = false;
    if (this.height === 0 || (!isMobile && this.height !== newHeight)) {
      this.height = newHeight;
      doResize = true;
    }
    if (this.width !== newWidth) {
      this.width = newWidth;
      this.height = newHeight;
      doResize = true;
    }

    if (doResize && this.hasCanvas) {
      this.$el.style.height = `${this.height}px`;
      this.$canvas.width = this.retina(this.width);
      this.$canvas.height = this.retina(this.height);

      this.$canvas.style.width = `${this.width}px`;
      this.$canvas.style.height = `${this.height}px`;
    }
    this.offsetTop = this.$el.offsetTop;
    this.updateScrollPosition();
  };
  this.resize();
  this.frame();
  window.addEventListener('resize', this.resize.bind(this));
  window.addEventListener('orientationchange', this.resize.bind(this));
  window.addEventListener('scroll', this.updateScrollPosition.bind(this));

  if (options.init) {
    options.init(this);
  }
}
