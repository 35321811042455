export function TOS() {
  let $els;
  let elLn;
  let viewportHeight;
  let containerRect;

  function throttledRender(blockModule) {
    return blockModule.throttle(() => { render(blockModule); }, 1000 / 10);
  }

  function render(blockModule) {

    if (!$els || !elLn) return;

    viewportHeight = window.innerHeight;
    containerRect = blockModule.$el.getBoundingClientRect();
    const viewportCenter = viewportHeight / 2;

    $els.forEach(($el) => {
      const rect = $el.getBoundingClientRect();
      const elementCenter = rect.top + rect.height / 2;

      // Calculate distance from center of viewport (normalized between 0 and 1)
      const distanceFromCenter = Math.abs(elementCenter - viewportCenter);
      const maxDistance = viewportHeight / 2;
      const normalizedDistance = Math.min(distanceFromCenter / maxDistance, 1);

      // Convert distance to brightness (1 = black, 0 = white)
      // Using exponential curve for smoother transition
      const brightness = Math.pow(normalizedDistance, 2);

      // Convert brightness to hex color (17 = 0x11)
      const colorValue = Math.floor(17 + (238 * (1 - brightness))).toString(16).padStart(2, '0');
      $el.style.color = `#${colorValue}${colorValue}${colorValue}`;
    });
  }

  function themeColor() {
    return '#000';
  }

  function init(blockModule) {
    $els = blockModule.$el.querySelectorAll('div');
    elLn = $els.length;

    // Initialize all text as black
    $els.forEach($el => {
      $el.style.color = '#111';
      $el.style.transition = 'color 0.25s ease-in-out';
    });

    // Initial render
    render(blockModule);
  }

  return {
    activeFrame: throttledRender,
    visibleFrame: throttledRender,
    invisibleFrame: throttledRender,
    themeColor,
    init,
    disableSnap: true,
  };
}
