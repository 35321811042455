import { BlockModule } from './blockmodule';
import {TOS} from './blockmodule.tos';


// import { Debug } from './debug';

document.addEventListener('DOMContentLoaded', () => {
  new BlockModule('#welcome', TOS());


  // new BlockModule('.debug', Debug());
  // new BlockModule('.debug2', Debug());
  // new BlockModule('#welcome3', Debug());
});
